import loadable from '@loadable/component';
import type { PageProps } from 'gatsby';
import { ThemeProvider } from 'next-themes';
import { useState } from 'react';
import PreviewLayout, { ErrorBoundary } from 'src/layouts/preview';
import { mdxComponents } from '../providers/markdown-provider';

const MDX = loadable(() => import('@mdx-js/runtime'));

const MDXPreview = ({ location }: PageProps) => {
  const [content, setContent] = useState('');

  return (
    <ThemeProvider attribute="class" enableSystem={false} disableTransitionOnChange>
      <PreviewLayout
        title="MDX Previewer"
        fileType=".md,.mdx"
        linkToGuide="/markdown-guide"
        withCodeSamples
        content={content}
        setContent={setContent}
        location={location}
        isGreyLayout={content.includes('isGreyLayout: true')}
      >
        <span data-pagefind-meta="title:MDX Previewer" />
        <span data-pagefind-meta="description:This tool allows you to previewer your MDX files to see how they will render on the Developer Portal." />
        <ErrorBoundary>
          <MDX components={mdxComponents}>{content.replaceAll(/(---(.|\n)*---\n)/gi, '')}</MDX>
        </ErrorBoundary>
      </PreviewLayout>
    </ThemeProvider>
  );
};

export default MDXPreview;
